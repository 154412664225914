
import { inject } from '@/inversify';
import { Vue, Component } from 'vue-property-decorator';
import { ChartDataSets } from 'chart.js';

import CURRENT_HOTEL_GRAPH_COLOR from '@/modules/common/constants/current-hotel-graph-color.constant';
import { DEFAULT_GRAPH_COLORS } from '@/modules/common/constants/default-graph-colors.constant';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';

import RVSOvertimeService, { RVSOvertimeServiceS } from '../rvs-overtime.service';

@Component({
    components: {
        CustomGraph,
    },
})
export default class RVSOvertimeGraph extends Vue {
    ranges = [7, 14, 30, 60];
    currentRange = 30;

    @inject(RVSOvertimeServiceS)
    private rvsOvertimeService!: RVSOvertimeService;

    get isLoading() {
        return this.rvsOvertimeService.isLoading;
    }

    get chartData() {
        if (this.isLoading) {
            return {
                datasets: [],
                labels: [],
            };
        }

        const labels = new Array(this.currentRange * 2 + 1)
            .fill(0)
            .map((_, i) => -this.currentRange + i);

        const mainRvsDataset = {
            data: this.rvsOvertimeService.getDataset(this.currentRange),
            backgroundColor: 'transparent',
            borderColor: CURRENT_HOTEL_GRAPH_COLOR,
            borderWidth: 2,
            pointBackgroundColor: 'transparent',
            pointBorderColor: 'transparent',
            lineTension: 0.2,
        } as ChartDataSets;

        const compsetAvgDataset = {
            data: this.rvsOvertimeService.getDataset(this.currentRange, true),
            backgroundColor: 'transparent',
            borderColor: DEFAULT_GRAPH_COLORS[9], // yellow
            borderWidth: 2,
            pointBackgroundColor: 'transparent',
            pointBorderColor: 'transparent',
            lineTension: 0.2,
            borderDash: [5, 5],
        } as ChartDataSets;

        return {
            labels,
            datasets: [
                mainRvsDataset,
                compsetAvgDataset,
            ],
        };
    }

    get chartOptions() {
        return {
            legend: { display: false },
            maintainAspectRatio: false,
            scales: {
                yAxes: [{
                    max: 100,
                    ticks: {
                        stepSize: 20,
                    },
                }],
            },
            tooltips: {
                enabled: false,
            },
        };
    }
}
