import { render, staticRenderFns } from "./rvs-score.vue?vue&type=template&id=70e05e71&scoped=true&"
import script from "./rvs-score.vue?vue&type=script&lang=ts&"
export * from "./rvs-score.vue?vue&type=script&lang=ts&"
import style0 from "./rvs-score.vue?vue&type=style&index=0&id=70e05e71&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70e05e71",
  null
  
)

export default component.exports