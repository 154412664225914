
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';

import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';

import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import RVSScoreContainer from '../components/rvs-score.vue';
import RVSRootCause from '../components/rvs-root-cause.vue';
import RVSRootCauseAnalysis from '../components/rvs-root-cause-analysis.vue';
import RVSOvertimeGraph from '../components/rvs-overtime.vue';
import RVSCalendar from '../components/rvs-calendar.vue';
import RVSScoreService from '../rvs-score.service';

@Component({
    components: {
        RVSScoreContainer,
        RVSRootCause,
        RVSRootCauseAnalysis,
        RVSOvertimeGraph,
        RVSCalendar,
        CustomSelect,
    },
})
export default class OverviewTab extends Vue {
    @inject(ProvidersServiceS)
    private providersService!: ProvidersService;

    @inject(KEY.RVSScoreService)
    private rvsScoreService!: RVSScoreService;

    get provider() {
        return this.rvsScoreService.settings.provider;
    }

    set provider(value: string | null) {
        this.rvsScoreService.settings.provider = value;
    }

    get providers() {
        return [
            {
                name: this.$tc('All Channels'),
                value: null,
            },
            ...this.providersService.toItemsList(this.providersService.ratesProviders),
        ];
    }
}
