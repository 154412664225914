var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{class:{
    'rvs-score-container block-card': true,
    ['rvs-score-container--' + _vm.color]: true,
  }},[_c('div',{staticClass:"rvs-score-container__headline"},[_c('h2',{staticClass:"rvs-overview__block-headline",domProps:{"textContent":_vm._s(_vm.$tc('rvs.score.title'))}}),_c('i',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            header: _vm.$tc('rvs.score.hintMessageTitle'),
            text: _vm.tooltipText,
            position: 'right',
          }),expression:"{\n            header: $tc('rvs.score.hintMessageTitle'),\n            text: tooltipText,\n            position: 'right',\n          }"}],staticClass:"icon-Icons_Qusetion-mark",on:{"mouseenter":_vm.toggleHover,"mouseleave":_vm.toggleHover}})]),_c('div',{staticClass:"rvs-score-container__content"},[_c('div',{staticClass:"rvs-score-container__score"},[(_vm.hasData || _vm.isLoading)?[_c('span',{class:{
                  'rvs-score-container__score-main': true,
                  'skeleton skeleton--gray': _vm.isLoading,
                },attrs:{"data-label":_vm.$t('rvs.score.mainScoreTitle')},domProps:{"textContent":_vm._s(_vm.mainScore)}}),_c('span',{staticClass:"rvs-score-container__score-divider"},[_vm._v(" vs ")]),_c('span',{class:{
                  'rvs-score-container__score-avg': true,
                  'skeleton skeleton--gray': _vm.isLoading
                },attrs:{"data-label":_vm.$t('rvs.score.avgScoreTitle')}},[_vm._v(" "+_vm._s(_vm.averageCompsetScore)+" ")]),_c('div',{class:{
                  'skeleton skeleton--gray': _vm.isLoading,
                  'rvs-score-container__grow-indicator': true,
                  'rvs-score-container__grow-indicator--up': _vm.difference > 0,
                  'rvs-score-container__grow-indicator--down': _vm.difference < 0,
                },domProps:{"textContent":_vm._s(_vm.difference + '%')}})]:_c('p',{staticClass:"rvs-score-container__no-data-label",domProps:{"textContent":_vm._s(_vm.$t('noData'))}})],2),(!_vm.isLoading)?[(_vm.difference > 0)?_c('p',{staticClass:"rvs-score-container__score-message",domProps:{"textContent":_vm._s(_vm.$tc('rvs.score.higherMessage', 0, [_vm.mainScore, Math.abs(_vm.difference)]))}}):_vm._e(),(_vm.difference < 0)?_c('p',{staticClass:"rvs-score-container__score-message",domProps:{"textContent":_vm._s(_vm.$tc('rvs.score.lowerMessage', 0, [_vm.mainScore, Math.abs(_vm.difference)]))}}):_vm._e()]:_c('p',{staticClass:"skeleton skeleton--gray",domProps:{"textContent":_vm._s(_vm.helperService.fill(80))}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }