
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';

import CustomTooltip from '@/modules/common/components/ui-kit/custom-tooltip-v2.vue';
import { ASSESSMENT_TYPES } from '@/modules/common/constants';

import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import RVSScoreService, { RVSScoreServiceS } from '../rvs-score.service';

@Component({
    components: {
        CustomTooltip,
    },
})
export default class RVSScoreContainer extends Vue {
    @inject(RVSScoreServiceS)
    private rvsScoreService!: RVSScoreService;

    @inject(HelperServiceS)
    helperService!: HelperService;

    hint: HTMLElement | null = null;

    get isLoading() {
        return this.rvsScoreService.isLoading;
    }

    get day() {
        return this.rvsScoreService.day;
    }

    get mainScore() {
        if (this.isLoading) return 99;

        return this.rvsScoreService.mainScore;
    }

    get hasData() {
        return this.rvsScoreService.hasData;
    }

    get averageCompsetScore() {
        if (this.isLoading) return 99;

        return this.rvsScoreService.averageCompsetScore || 0;
    }

    get tooltipText() {
        if (!this.hasData) {
            return this.$tc('rvs.score.hintMessage');
        }

        return [
            this.$tc('rvs.score.hintMessage'),
            this.$tc(`rvs.score.hintScoreAdvice._${this.assessment}`, 0, [this.mainScore]),
        ].join(' ');
    }

    get difference() {
        if (this.isLoading) return 99;

        return this.rvsScoreService.difference;
    }

    get assessment() {
        return this.rvsScoreService.assessment;
    }

    get color() {
        if (!this.hasData) {
            return 'grey';
        }

        switch (this.assessment) {
            case ASSESSMENT_TYPES.GOOD: return 'green';
            case ASSESSMENT_TYPES.BAD: return 'red';
            default: return 'yellow';
        }
    }

    toggleHover(e: MouseEvent) {
        this.hint = this.hint ? null : e.target as HTMLElement;
    }
}
