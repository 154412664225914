
import { inject } from '@/inversify';
import { Component, Vue } from 'vue-property-decorator';

import UserService, { UserServiceS } from '@/modules/user/user.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';

@Component({
    components: {},
})
export default class RVSHeader extends Vue {
    @inject(UserServiceS)
    private userService!: UserService;

    @inject(HotelsServiceS)
    private hotelsService!: HotelsService;

    get isHotelUser() {
        return !this.userService.isChainOrClusterUser;
    }

    get hotelName(): string | null {
        const { currentHotelId = +this.$route.params.hotelId } = this.userService;

        if (!currentHotelId) {
            return null;
        }

        return this.hotelsService.getHotelName(currentHotelId);
    }
}

