var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"rvs-root-cause block-card"},[_c('div',{staticClass:"rvs-root-cause__headline"},[_c('h2',{staticClass:"rvs-overview__block-headline",domProps:{"textContent":_vm._s(_vm.$t('rvs.rootCause.title'))}}),_vm._v(" "+_vm._s(_vm.date)+" ")]),_c('div',{staticClass:"rvs-root-cause__pie-chart-container"},[(_vm.chartData)?_c('CustomGraph',{ref:"graph",staticClass:"rvs-root-cause__pie-chart",attrs:{"type":"doughnut","chartData":_vm.chartData,"options":_vm.options,"intersect":false,"isTooltip":""},scopedSlots:_vm._u([{key:"tooltip",fn:function(tooltipProps){return [_c('div',{class:{
                        'rvs-root-cause__tooltip block-card': true,
                        'rvs-root-cause__tooltip--is-left': tooltipProps.tooltipModel.x < 100,
                    },style:({
                        position: 'absolute',
                        left: tooltipProps.tooltipModel.x + 'px',
                        top: tooltipProps.tooltipModel.y + 'px',
                    })},[_c('div',{staticClass:"rvs-root-cause__indicator",style:({
                            '--point-color': _vm.colors[tooltipProps.hoverIndex],
                        })}),_vm._v(" "+_vm._s(_vm.$tc(`rvs.rootCause.${ _vm.labels[tooltipProps.hoverIndex] }`))+": "+_vm._s(Math.round(_vm.rootCause[tooltipProps.hoverIndex][1] * 100))+"% ")])]}}],null,false,3155682008)}):_c('div',{domProps:{"textContent":_vm._s(_vm.$tc('noData'))}})],1),_c('div',{staticClass:"rvs-root-cause__legend-measure-container"},[_c('ul',{staticClass:"rvs-root-cause__legend"},_vm._l((_vm.labels),function(label,index){return _c('li',{key:label,style:({
                    '--point-color': _vm.colors[index],
                }),domProps:{"textContent":_vm._s(_vm.$tc(`rvs.rootCause.${label}`))}})}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }