
import { Component, Vue } from 'vue-property-decorator';

import DateDocumentFilter from '@/modules/document-filters/components/date-document-filter.vue';
import HomeHotelTitle from '@/modules/home/components/home-hotel-title.vue';
import PageWrapper from '@/modules/common/components/page-wrapper.vue';

import OverviewTab from '../tabs/overview.tab.vue';
import RVSHeader from '../components/rvs-header.vue';

@Component({
    components: {
        DateDocumentFilter,
        HomeHotelTitle,
        PageWrapper,
        OverviewTab,
        RVSHeader,
    },
})
export default class OverviewPage extends Vue {}
